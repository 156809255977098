@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNextLTPro-Regular.woff2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNextLTPro-Demi.woff2');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@layer components {
  .text-r-5xl {
    @apply text-3xl xs:text-4xl md:text-5xl;
  }
  .text-r-4xl {
    @apply text-2xl xs:text-3xl md:text-4xl;
  }
  .text-r-3xl {
    @apply text-xl xs:text-2xl md:text-3xl;
  }
  .text-r-2xl {
    @apply text-lg xs:text-xl md:text-2xl;
  }
  .text-r-xl {
    @apply text-base xs:text-lg md:text-xl;
  }
  .text-r-lg {
    @apply text-base md:text-lg;
  }
  .text-r-base {
    @apply text-base;
  }
  .text-r-sm {
    @apply text-xs md:text-sm;
  }
  .px-container {
    @apply px-4 sm:px-8 lg:px-16 xl:px-20;
  }
  .btn-primary {
    @apply inline-flex items-center whitespace-nowrap rounded-md border-2 border-primary bg-primary px-8 py-3 font-bold text-white transition-colors hover:border-primary-dark hover:bg-primary-dark active:bg-primary-dark disabled:pointer-events-none disabled:grayscale;
  }
  .btn-secondary {
    @apply inline-flex items-center whitespace-nowrap rounded-md border-2 border-primary px-8 py-3 font-bold transition-colors hover:border-primary-dark hover:bg-primary-dark disabled:pointer-events-none disabled:grayscale;
  }
}

::selection {
  @apply bg-primary text-white;
}

.marquee {
  /* Temp fix for https://github.com/justin-chu/react-fast-marquee/issues/7 */
  min-width: max-content !important;
}

.grecaptcha-badge {
  /* Hide recaptcha https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed */
  visibility: hidden;
}

.rich-text table p {
  padding: 0px 4px;
}
.rich-text table td {
  padding: 0px 4px;
}
